import React, { useState } from 'react';
import { Tiles } from '../../components';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import { BsExclamationCircle } from 'react-icons/bs'; // Import the error icon
import './Footer.scss';

const Footer = () => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [selector, setSelector] = useState('');

    const { username, email, message } = formData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        // Clear validation error when user starts typing again
        setValidationError('');
    };

    const handleSubmit = () => {
        if (!username || !email || !message) {
            setValidationError('Please fill in all fields.');
            return;
        }

        if (!isValidEmail(email)) {
            setValidationError('Please enter a valid email address.');
            return;
        }

        if (selector === '') {
            setValidationError('Please select a category.');
            return;
        }

        setLoading(true);

        const contact = {
            _type: 'contact',
            name: formData.username,
            email: formData.email,
            category: selector,
            message: formData.message,
        };

        client.create(contact)
            .then(() => {
                setLoading(false);
                setIsFormSubmitted(true);
            })
            .catch((err) => console.log(err));
    };

    const isValidEmail = (email) => {
        // Robust email validation using regular expression
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };

    return (
        <>
            <h2 className="head-text">Have A Project? Contact Us Here!</h2>

            <div className="app__footer-cards">
                <div className="app__footer-card ">
                    <img src={images.email} alt="email" />
                    <a href="mailto:support@vexabyte.in" className="p-text">support@vexabyte.in</a>
                </div>
                <div className="app__footer-card">
                    <img src={images.mobile} alt="phone" />
                    <a href="https://api.whatsapp.com/send?phone=918329648782" target="_blank" className="p-text">+918329648782</a>
                </div>
            </div>
            <div className='app__footer-shape-image-four'>
                <img className='app_footer-shapeimage-shape-four' src={images.bgImageFour} alt="image" />
            </div>
            <div className='app__footer-shape-image-five'>
                <img className='app_footer-shapeimage-shape-five' src={images.bgImageFive} alt="image" />
            </div>
            <div className='app__footer-shape-image-six'>
                <img className='app_footer-shapeimage-shape-six' src={images.bgImageSix} alt="image" />
            </div>
            {!isFormSubmitted ? (
                <div className="app__footer-form app__flex">
                    <div className="app__flex">
                        <input className="p-text" type="text" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} />
                    </div>
                    <div className="app__flex">
                        <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
                    </div>
                    <div>
                        <textarea
                            className="p-text"
                            placeholder="Your Message"
                            value={message}
                            name="message"
                            onChange={handleChangeInput}
                        />
                    </div>

                    <Tiles setSelector={setSelector} />

                    <button type="button" className="p-text app__footer-button" onClick={handleSubmit}>
                        {!loading ? 'Send Message' : 'Sending...'}
                    </button>
                    {validationError && (
                        <p className={`error-message ${validationError ? 'show-error' : ''}`}>
                            <div className="error-icon">
                                <BsExclamationCircle className="icon" />
                                <div className="error-text">{validationError}</div>
                            </div>

                        </p>
                    )}
                </div>
            ) : (
                <div>
                    <h3 className="head-text">
                        Thank you for getting in touch!
                    </h3>
                </div>
            )}
        </>
    );
};

export default AppWrap(
    MotionWrap(Footer, 'app__footer'),
    'contact',
    'app__primarybg',
);