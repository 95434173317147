import React, { useEffect, useState } from 'react';
import { AiFillMobile } from 'react-icons/ai';
import { FaDatabase } from 'react-icons/fa';
import { SiVorondesign } from 'react-icons/si';
import { RiComputerFill } from 'react-icons/ri';
import './Tiles.scss';

const Tiles = (props) => {

    const [checkedRadio, setCheckedRadio] = useState(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            
            if (event.target.closest('.app__footer-radio-tile-group') === null) {
                
                setCheckedRadio(null);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleRadioChange = (event) => {
        setCheckedRadio(event.target.id);
        props.setSelector(event.target.id);
    };

    return (
        <div className='app__footer-tiles'>
            <div className="app__footer-container-tiles">
                <div className="app__footer-radio-tile-group">

                    <div className="app__footer-input-container">
                        <input
                            id="web"
                            type="radio"
                            name="radio"
                            checked={checkedRadio === "web"}
                            onChange={handleRadioChange}
                        />
                        <div className="app__footer-radio-tile">

                            <RiComputerFill className='app__footer-tiles-icon' />
                            <label for="web">Web</label>
                        </div>
                    </div>

                    <div className="app__footer-input-container">
                        <input
                            id="app"
                            type="radio"
                            name="radio"
                            checked={checkedRadio === "app"}
                            onChange={handleRadioChange}
                        />
                        <div className="app__footer-radio-tile">

                            <AiFillMobile className='app__footer-tiles-icon' />
                            <label for="app">Mobile</label>
                        </div>
                    </div>

                    <div className="app__footer-input-container">
                        <input
                            id="backend"
                            type="radio"
                            name="radio"
                            checked={checkedRadio === "backend"}
                            onChange={handleRadioChange}
                        />
                        <div className="app__footer-radio-tile">

                            <FaDatabase className='app__footer-tiles-icon' />
                            <label for="backend">Backend</label>
                        </div>
                    </div>

                    <div className="app__footer-input-container">
                        <input
                            id="designing"
                            type="radio"
                            name="radio"
                            checked={checkedRadio === "designing"}
                            onChange={handleRadioChange}
                        />
                        <div className="app__footer-radio-tile">

                            <SiVorondesign className='app__footer-tiles-icon' />
                            <label for="designing">Graphic</label>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Tiles;