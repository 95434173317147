import React, { useState, useEffect } from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Team.scss'
import { motion } from 'framer-motion';
import { BsLinkedin, BsYoutube } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { images } from '../../constants';
import { urlFor, client } from '../../client';

const Team = () => {
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        const query = '*[_type == "teams"]';

        client.fetch(query)
            .then((data) => setTeams(data))
    }, []);

    return (
        <>
            <h2 className='head-text'>Our Team</h2>

            <div className='app__teams'>
                {teams.map((team, index) => (
                    <motion.div
                        whileInView={{ opacity: 1 }}
                        whileHover={{ transform: 'translateY(-10px)', boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)' }}
                        transition={{ duration: 0.5, type: 'tween' }}
                        className="app__teams-item"
                        key={team.title + index}
                    >
                        <div className='app__teams-card'>
                            <div className='app__teams-content'>
                                <div className='app__teams-imgBx'>
                                    <img src={urlFor(team.imgUrl)} alt={team.title} />
                                </div>
                                <div className='app__teams-contentBx'>
                                    <h2 className='bold-text' style={{ marginTop: 20 }}>{team.title}</h2>
                                    <p className='p-text' style={{ marginTop: 10 }}>{team.description}</p>
                                </div>
                            </div>
                            <ul className='app__teams-sci'>

                                {(team.email?.trim() != '' && team.email) ? (
                                    <li style={{ '--i': 1 }}>
                                        <a href={`mailto:${team.email}`}><MdEmail /></a>
                                    </li>
                                ) : (
                                    null
                                )}

                                {(team.linkedin?.trim() != '' && team.linkedin) ? (
                                    <li style={{ '--i': (team.email?.trim() != '' && team.email) ? 2 : 1 }}>
                                        <a href={team.linkedin} target="_blank"><BsLinkedin /></a>
                                    </li>
                                ) : (
                                    null
                                )}

                                {console.log(team.youtube)}
                                {(team.youtube?.trim() != '' && team.youtube) ? (
                                    <li style={{ '--i': ((team.linkedin?.trim() != '' && team.linkedin) && (team.email?.trim() != '' && team.email)) ? 3 : (team.linkedin?.trim() != '' && team.linkedin) ? 2 : (team.email?.trim() != '' && team.email) ? 2 : 1 }}>
                                        <a href={team.youtube} target="_blank"><BsYoutube /></a>
                                    </li>
                                ) : (
                                    null
                                )}



                                {/* {team.email?.trim() !== '' ? (
                                    <li style={{ '--i': 1 }}>
                                        <a href="#"><MdEmail /></a>
                                    </li>
                                ) : null}

                                {team.linkedin?.trim() !== '' ? (
                                    <li style={{ '--i': team.email?.trim() !== '' ? 2 : 1 }}>
                                        <a href="#"><BsLinkedin /></a>
                                    </li>
                                ) : null}

                                {team.youtube?.trim() !== '' ? (
                                    <li style={{ '--i': team.linkedin?.trim() !== '' ? 3 : 2 }}>
                                        <a href="#"><BsYoutube /></a>
                                    </li>
                                ) : null}

                                {team.facebook?.trim() !== '' ? (
                                    <li style={{ '--i': team.youtube?.trim() !== '' ? 4 : 3 }}>
                                        <a href="#"><BsFacebook /></a>
                                    </li>
                                ) : null} */}


                                {/* <li style={{ '--i': 2 }}>
                                    <a href="#"><BsLinkedin /></a>
                                </li>
                                <li style={{ '--i': 3 }}>
                                    <a href="#"><BsYoutube /></a>
                                </li>
                                <li style={{ '--i': 4 }}>
                                    <a href="#"><BsFacebook /></a>
                                </li> */}
                            </ul>
                        </div>

                        {/* <img src={urlFor(team.imgUrl)} alt={team.title} />
                        <h2 className='bold-text' style={{ marginTop: 20 }}>{team.title}</h2>
                        <p className='p-text' style={{ marginTop: 10 }}>{team.description}</p> */}
                    </motion.div>
                ))}
            </div>

            <div className='app__teams-shape-image-one'>
                <img className='app_teams-shapeimage-shape-one' src={images.bgImageOne} alt="image" />
            </div>
            <div className='app__teams-shape-image-two'>
                <img className='app_teams-shapeimage-shape-two' src={images.bgImageTwo} alt="image" />
            </div>
            <div className='app__teams-shape-image-three'>
                <img className='app_teams-shapeimage-shape-three' src={images.bgImageThree} alt="image" />
            </div>
        </>
    )
}

export default AppWrap(
    MotionWrap(Team, 'app__team'),
    'team',
    "app__primarybg"
);