import React from 'react';
import { BsFillTelephoneFill, BsWhatsapp } from 'react-icons/bs';
import { BiLogoGmail } from 'react-icons/bi';

const SocialMedia = () => {
    return (
        <div className='app__social'>
            <div>
                <a href='tel:+918329648782'><BsFillTelephoneFill /></a>
            </div>
            <div>
                <a href="https://api.whatsapp.com/send?phone=918329648782" target="_blank"><BsWhatsapp /></a>
            </div>
            <div>
                <a href="mailto:support@vexabyte.in"><BiLogoGmail /></a>
            </div>
        </div>
    )
}

export default SocialMedia