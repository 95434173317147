import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './Pricing.scss';
import { BsFillXCircleFill, BsPatchCheck } from 'react-icons/bs';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import { images } from '../../constants';

const Pricing = () => {

    const [plans, setPlans] = useState([]);

    useEffect(() => {
        const query = '*[_type == "plans"]';

        client.fetch(query)
            .then((data) => setPlans(data))
    }, [])


    return (
        <>
            <h2 className='head-text'>Choose The Pricing Plan</h2>

            <div className='app__plans'>
                {plans.map((plan, index) => (
                    <motion.div
                        whileInView={{ opacity: 1 }}
                        whileHover={{ transform: 'translateY(-10px)', boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)' }}
                        transition={{ duration: 0.5, type: 'tween' }}
                        className="app__plan-item"
                        key={plan.title + index}
                    >
                        <h2 className='bold-text'>{plan.title}</h2>
                        <hr></hr>
                        <p className='app__plan-item-price'>{plan.price}</p>
                        <hr></hr>
                        <div className='app__plan-item-main-div'>

                            {plan.checkPlanOne ? (
                                <p><BsPatchCheck className='app__plan-item-check-icon' />{plan.planOne}</p>
                            ) : (
                                <p><BsFillXCircleFill className='app__plan-item-cross-icon' />{plan.planOne}</p>
                            )}

                            {plan.checkPlanTwo ? (
                                <p><BsPatchCheck className='app__plan-item-check-icon' />{plan.planTwo}</p>
                            ) : (
                                <p><BsFillXCircleFill className='app__plan-item-cross-icon' />{plan.planTwo}</p>
                            )}

                            {plan.checkPlanThree ? (
                                <p><BsPatchCheck className='app__plan-item-check-icon' />{plan.planThree}</p>
                            ) : (
                                <p><BsFillXCircleFill className='app__plan-item-cross-icon' />{plan.planThree}</p>
                            )}

                            {plan.checkPlanFour ? (
                                <p><BsPatchCheck className='app__plan-item-check-icon' />{plan.planFour}</p>
                            ) : (
                                <p><BsFillXCircleFill className='app__plan-item-cross-icon' />{plan.planFour}</p>
                            )}

                            {plan.checkPlanFive ? (
                                <p><BsPatchCheck className='app__plan-item-check-icon' />{plan.planFive}</p>
                            ) : (
                                <p><BsFillXCircleFill className='app__plan-item-cross-icon' />{plan.planFive}</p>
                            )}

                            {plan.checkPlanSix ? (
                                <p><BsPatchCheck className='app__plan-item-check-icon' />{plan.planSix}</p>
                            ) : (
                                <p><BsFillXCircleFill className='app__plan-item-cross-icon' />{plan.planSix}</p>
                            )}

                            {plan.checkPlanSeven ? (
                                <p><BsPatchCheck className='app__plan-item-check-icon' />{plan.planSeven}</p>
                            ) : (
                                <p><BsFillXCircleFill className='app__plan-item-cross-icon' />{plan.planSeven}</p>
                            )}
                        </div>
                        {/* <button className='app__plan-item-button app__plan-item-button-one'>SELECT THE PLAN</button> */}
                    </motion.div>
                ))}
            </div>

            <div className='app__plans-shape-image-one'>
                <img className='app_plans-shapeimage-shape-one' src={images.bgImageOne} alt="image" />
            </div>
            <div className='app__plans-shape-image-two'>
                <img className='app_plans-shapeimage-shape-two' src={images.bgImageTwo} alt="image" />
            </div>
            <div className='app__plans-shape-image-three'>
                <img className='app_plans-shapeimage-shape-three' src={images.bgImageThree} alt="image" />
            </div>
        </>
    )
}

export default AppWrap(
    MotionWrap(Pricing, 'app__plan'),
    'plan',
    "app__whitebg"
);